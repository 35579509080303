
/* eslint-disable no-unused-vars */
import { debounce } from 'lodash';

import axios from 'axios';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  CondOperator,
  CreateQueryParams,
  RequestQueryBuilder,
  SConditionAND,
  SFields,
} from '@dataui/crud-request';
import { getModule } from 'vuex-module-decorators';

import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import CrudListPagination from '@gid/vue-common/components/crud-list/CrudListPagination.vue';
import CrudListTable from '@gid/vue-common/components/crud-list/CrudListTable.vue';
import CrudListErrorMessage from '@gid/vue-common/components/crud-list/CrudListErrorMessage.vue';
import {
  AccountingDocument,
  AccountingDocumentStatusEnum,
  AccountingDocumentTargetEnum,
  AccountingDocumentTypeEnum,
  DocumentationTypeEnum,
  Email,
  statusTransitionForAccountingDocument,
  UserRoleEnum,
  PaymentReminderStatusEnum,
} from '@gid/models';

import {
  AccountingDocumentsActionsEnum,
  AccountingDocumentsModule,
} from '../../store/accounting-documents.module';
import { TranslateResult } from 'vue-i18n';
import { ContactProfileEnum } from '@gid/models/dist/entities/contact-profile-enum';
import WarningBadges from '@/components/accounting/WarningBadges.vue';

interface TargetStatusSelection {
  key: string;
  value:
    | AccountingDocumentStatusEnum
    | 'separator'
    | 'retry-pending'
    | 'send-reminder';
  $isDisabled?: boolean;
}

@Component({
  components: {
    DatePicker,
    CrudListPagination,
    CrudListTable,
    CrudListErrorMessage,
    WarningBadges,
  },
})
export default class AccountingDocumentsView extends Vue {
  @Prop() documentId?: string;
  documentStatuses = AccountingDocumentStatusEnum;

  targetStatus: TargetStatusSelection | null = null;
  inProgress: boolean = false;

  created() {
    this.refreshDocumentList();
  }

  exportForDatev() {
    const { search } = this.buildSearch();
    const query = {
      page: undefined,
      sort: undefined,
      limit: undefined,
      filter: undefined,
    };
    const qb = RequestQueryBuilder.create(query);
    if (search) {
      qb.search(search);
    }
    const queryString = qb.query();
    const path = `/documents-api/accounting/documents/csv?${queryString}&jwt=${this.store?.store?.getters?.access_token}`;
    window.open(path);
  }
  // Computed
  get store(): AccountingDocumentsModule {
    return getModule(AccountingDocumentsModule, this.$store);
  }
  get filterStatusOptions() {
    return Object.values(AccountingDocumentStatusEnum);
  }
  get filterDocumentTypes() {
    return Object.values(AccountingDocumentTypeEnum);
  }
  get filterTargetTypeOptions() {
    return Object.values(AccountingDocumentTargetEnum);
  }
  get filterReminderStatuseOptions() {
    return Object.values(PaymentReminderStatusEnum);
  }
  get isProductPowerUser() {
    return this.$store.getters.contactProfiles?.includes(
      ContactProfileEnum.PRODUCT_POWER_USER,
    );
  }
  get targetStatusOptions(): TargetStatusSelection[] {
    const options: TargetStatusSelection[] = [
      {
        key: 'Send',
        value: AccountingDocumentStatusEnum.SEND_PENDING,
        $isDisabled: false,
      },
      {
        key: 'Cancel document',
        value: AccountingDocumentStatusEnum.CANCELLATION_PENDING,
        $isDisabled: false,
      },
      {
        key: 'Send cancellation document',
        value: AccountingDocumentStatusEnum.CANCELLATION_SEND_PENDING,
        $isDisabled: false,
      },
    ];

    const allowedTo: { [k: string]: boolean } = {};
    for (const option of options) {
      let status = option.value as AccountingDocumentStatusEnum;
      if (Object.values(AccountingDocumentStatusEnum).includes(status)) {
        allowedTo[status] = true;
        for (const doc of this.store.selectedItems) {
          allowedTo[status] =
            allowedTo[status] &&
            statusTransitionForAccountingDocument.isAllowed(
              doc.status,
              status,
              this.$store.state.auth.user?.contact?.profiles ?? [],
            );
          if (
            status == AccountingDocumentStatusEnum.SEND_PENDING &&
            doc.hasReclamationInProject &&
            !this.isProductPowerUser
          ) {
            allowedTo[status] = false;
            break;
          }
        }
      }
    }
    let allPending = this.store.selectedItems.every((doc) =>
      doc.status.endsWith(AccountingDocumentStatusEnum.PENDING),
    );
    let allSent = this.store.selectedItems.every(
      (doc) => doc.status === AccountingDocumentStatusEnum.SENT,
    );
    const actionsOptions = options
      .map((x) => ({
        ...x,
        $isDisabled: x.value in allowedTo ? !allowedTo[x.value] : true,
      }))
      .concat([
        {
          key: '---',
          value: 'separator',
          $isDisabled: true,
        },
        {
          key: 'Retry pending documents',
          value: 'retry-pending',
          $isDisabled: !allPending,
        },
      ]);
    return this.store.selectedItems.every(
      (doc) => doc.type === AccountingDocumentTypeEnum.INVOICE,
    )
      ? actionsOptions.concat({
          key: 'Send Payment Reminder',
          value: 'send-reminder',
          $isDisabled: !allSent,
        })
      : actionsOptions;
  }
  get columns() {
    return [
      {
        key: 'issueDate',
        sortable: true,
        label: this.$t('accounting.document.issue-date'),
      },
      {
        key: 'number',
        sortable: true,
        label: this.$t('accounting.common.document-number'),
      },
      {
        key: 'cancellationNumber',
        sortable: true,
        label: this.$t('accounting.common.document-cancellation-number'),
      },
      {
        key: 'targetName',
        sortable: true,
        label: this.$t('accounting.common.target-name'),
      },
      {
        key: 'target.creditorNumber',
        sortable: true,
        label: this.$t('accounting.common.creditor-number'),
      },
      {
        key: 'target.debitorNumber',
        sortable: true,
        label: this.$t('accounting.common.debitor-number'),
      },
      {
        key: 'totalAmount',
        sortable: true,
        label: this.$t('accounting.common.total-amount'),
      },
      {
        key: 'type',
        sortable: true,
        label: this.$t('accounting.common.document-type'),
      },
      {
        key: 'status',
        sortable: true,
        label: this.$t('accounting.common.document-status'),
      },
    ];
  }

  getTargetEmail(item) {
    const {
      emails,
      target: { emailForInvoices },
    } = item;

    const emailRegex =
      /accounting\/invoice\/(first|second|third)_reminder|send_document$/;
    const defaultEmail =
      'auftrag@emailbrief.de <i class="material-icons">local_shipping</i>';

    // Find the latest matching email in one pass
    const lastEmail = emails.reduce((latest, email) => {
      if (emailRegex.test(email.template)) {
        // Check if it's the latest email
        return !latest || new Date(email.timestamp) > new Date(latest.timestamp)
          ? email
          : latest;
      }
      return latest;
    }, null);

    // Return the target email or default message
    return (
      lastEmail?.to?.[0] ||
      this.$t('accounting.document.email-will-sent', {
        email: emailForInvoices || defaultEmail,
      })
    );
  }

  @Watch('store.filters', {
    deep: true,
  })
  searchChange(value) {
    this.throttledRefresh();
  }

  buildSearch() {
    const search: Array<SFields | SConditionAND> = [];
    if (this.documentId) {
      const docToSearch = (docId) => {
        const fieldSearch: Array<SFields | SConditionAND> = [];
        let field = /^\d+$/.test(docId) ? 'id' : 'number';

        fieldSearch.push({
          [field]: {
            $eq: docId,
          },
        });

        if (field === 'number') {
          fieldSearch.push({
            outsideCommunicationNumber: {
              $eq: docId,
            },
          });
        }
        return fieldSearch;
      };

      return {
        search: { $or: this.documentId.split(',').flatMap(docToSearch) },
      };
    }

    if (this.store.filters.dateFrom) {
      search.push({
        issueDate: {
          $gte: this.store.filters.dateFrom,
        },
      });
    }
    if (this.store.filters.dateTo) {
      search.push({
        issueDate: {
          $lte: this.store.filters.dateTo,
        },
      });
    }
    if (this.store.filters.status) {
      search.push({
        status: {
          $eq: this.store.filters.status,
        },
      });
    }
    if (this.store.filters.targetType) {
      search.push({
        'lineItems.targetType': {
          $eq: this.store.filters.targetType,
        },
      });
    }
    if (this.store.filters.reminderStatus) {
      search.push({
        paymentReminderStatus: {
          $eq: this.store.filters.reminderStatus,
        },
      });
    }
    if (this.store.filters.paymentReminderBlocked) {
      search.push({
        'jobs.paymentReminderBlocker': {
          $eq: this.store.filters.paymentReminderBlocked,
        },
      });
    }
    if (this.store.filters.docType) {
      search.push({
        type: {
          $eq: this.store.filters.docType,
        },
      });
    }

    const searchTerm = this.store.filters.search.trim();
    if (searchTerm) {
      search.push({
        $or: [
          {
            number: {
              [CondOperator.CONTAINS_LOW]: searchTerm,
            },
          },
          {
            'jobs.name': {
              [CondOperator.CONTAINS_LOW]: searchTerm,
            },
          },
          {
            outsideCommunicationNumber: {
              [CondOperator.CONTAINS_LOW]: searchTerm,
            },
          },
          {
            'target.name': {
              [CondOperator.CONTAINS_LOW]: searchTerm,
            },
          },
          {
            cancellationNumber: {
              [CondOperator.CONTAINS_LOW]: searchTerm,
            },
          },
        ],
      });
    }

    return {
      search: {
        $and: search,
      },
    };
  }

  refreshDocumentList() {
    const { search } = this.buildSearch();

    const query: CreateQueryParams = {
      page: this.store.pagination.currentPage,
      limit: this.store.pagination.perPage,
    };
    this.store[AccountingDocumentsActionsEnum.ITEMS_FETCH]({
      query,
      search,
    });
  }
  throttledRefresh = debounce(this.refreshDocumentList, 400);

  hideModal() {
    this.$bvModal.hide('remainder-modal');
  }

  showModal() {
    this.$bvModal.show('remainder-modal');
  }
  modalSendReminder() {
    this.sendReminder();
    this.hideModal();
  }

  setStatus() {
    if (this.targetStatus?.value === 'retry-pending') {
      if (
        confirm(
          'Make sure you know what you are doing!\n If not sure please consult engineering.',
        )
      ) {
        return this.retryPending();
      }
      return;
    }

    if (this.targetStatus?.value === 'send-reminder') {
      if (
        this.store.selectedItems.find((item) =>
          item.emails.find((e) => e.template.indexOf('third_reminder') != -1),
        )
      ) {
        return this.showModal();
      }
      return this.sendReminder();
    }
    const newStatus = this.targetStatus?.value as AccountingDocumentStatusEnum;
    if (
      !newStatus ||
      !Object.values(AccountingDocumentStatusEnum).includes(newStatus as any)
    ) {
      console.warn('Documents.setStatus Invalid status ', newStatus);
      return;
    }
    const bulk = this.store.selectedItems.map((fullDoc) => {
      const doc: Partial<AccountingDocument> = new AccountingDocument();
      doc.id = fullDoc.id;
      doc.status = newStatus;
      return doc;
    });

    this.inProgress = true;
    this.store[AccountingDocumentsActionsEnum.ITEMS_UPDATE_ITEMS]({
      data: bulk,
      disableBulk: true,
      updatedFields: ['status'],
    })
      .then(() => {
        this.store.selectionSetAll(false);
        return this.refreshDocumentList();
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  async sendReminder() {
    const docs = this.store.selectedItems
      .filter((doc) => doc.status === AccountingDocumentStatusEnum.SENT)
      .map((doc) => doc.id);
    this.inProgress = true;
    Promise.all(
      docs.map((id) => {
        return axios.post(`${this.store.crudEndpoint}/reminder`, { id });
      }),
    )
      .then((res) => {
        return this.refreshDocumentList();
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  retryPending() {
    const docs = this.store.selectedItems.map((fullDoc) => {
      const doc: Partial<AccountingDocument> = new AccountingDocument();
      doc.id = fullDoc.id;
      return doc;
    });

    this.inProgress = true;
    axios
      .post(`${this.store.crudEndpoint}/retry-pending`, docs)
      .then(() => {
        this.store.selectionSetAll(false);
        return this.refreshDocumentList();
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  remindersInfo(allEmails: Email[]) {
    const remindersRegEx = /accounting\/invoice\/(first|second|third)_reminder/;
    const lastReminder = [...allEmails]
      .sort(
        (a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
      )
      .find((email) => remindersRegEx.test(email.template));
    if (lastReminder) {
      const name = lastReminder.template.match(remindersRegEx)?.[1];
      const label = this.$t(`reminders.${name}_reminder`);
      const date = new Date(lastReminder.timestamp).toLocaleString();
      return `${label} ${date}`;
    }
  }
}
